.erp-app {
  .mat-button {
    &:hover {
      .mat-button-focus-overlay {
        background-color: transparent;
      }
    }
  }

  erp-button {
    button {
      height: 2rem;
    }

    &[dense] {
      button {
        padding: 0;
      }
    }

    &[medium] {
      button {
        min-width: 9.375rem;
      }
    }

    &[wide] {
      button {
        min-width: 15rem;
      }
    }
  }

  &.erp-app-tablet,
  .erp-tablet-module {
    erp-button {
      button {
        height: 3rem;
      }

      &[medium] {
        button {
          min-width: 17.5rem;
        }
      }

      &[small] {
        button {
          min-width: 9.25rem;
        }
      }

      &[medium-height] {
        button {
          height: 4rem;
        }
      }

      &[wide] {
        button {
          min-width: 22.5rem;
        }
      }

      &[big] {
        button {
          min-width: 27.75rem;
          height: 10rem;
        }
      }
    }
  }
}
