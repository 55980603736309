// To allow using group selectors with pseudoclasses and avoid copy-paste
/* stylelint-disable no-descending-specificity */
@import 'variables/color';
@import 'mixins/truncate';

$min-column-width: 90px;
$header-padding: 10px;
$min-header-width: $min-column-width - 2 * $header-padding;

.erp-app {
  .mat-table {
    width: 100%;
    table-layout: fixed;

    .mat-header-cell {
      position: relative;
      min-width: $min-column-width;

      .resize-holder {
        cursor: col-resize;
        width: #{2 * $header-padding};
        height: 100%;
        position: absolute;
        right: -#{$header-padding};
        top: 0;
        z-index: 1000;
      }

      &:last-of-type {
        overflow: hidden;

        .resize-holder {
          right: 0;
        }
      }
    }

    &.max-width .mat-cell {
      max-width: $min-column-width;
    }

    .mat-cell {
      @include truncate;

      .mat-button {
        font-size: 0.875rem;

        &.erp-link-button:not([disabled]) {
          color: $blue;
        }
      }
    }

    &.resizing {
      user-select: none;
      cursor: col-resize;

      .mat-header-cell {
        pointer-events: none;
      }
    }

    .mat-cell,
    .mat-header-cell {
      border-right: 1px solid rgba(0, 0, 0, 12%);
      &:not(:nth-child(1)) {
        padding: 0 $header-padding;
      }

      p,
      span {
        @include truncate;

        margin: 0;
      }
    }

    thead {
      position: relative;

      th {
        .required::before {
          content: '*';
        }
      }
    }

    .mat-sort-header-container {
      flex-direction: row-reverse;
      justify-content: flex-end;
      position: relative;
      z-index: 1;
      padding-right: 0;
      min-width: $min-header-width;

      .mat-sort-header-arrow {
        margin: 0 0.5rem 0 0;
      }

      .mat-sort-header-content {
        width: calc(100% - 2 * #{$header-padding});
        justify-content: space-between;
      }

      .mat-sort-header-button {
        width: calc(100% - 2 * #{$header-padding});
        justify-content: space-between;
      }

      &:not(.mat-sort-header-sorted) {
        .mat-sort-header-arrow {
          opacity: 0.4 !important;
          transform: translateY(0) !important;
        }
      }
    }

    .mat-row {
      height: 2rem;

      .mat-button {
        line-height: unset;
        min-width: 2rem;
      }
    }

    .mat-header-cell,
    .mat-header-cell:first-of-type,
    .mat-header-cell:last-of-type,
    .mat-cell,
    .mat-cell:first-of-type,
    .mat-cell:last-of-type,
    .mat-footer-cell {
      font-size: 0.875rem;
      font-weight: 500;
      padding: 0 0.5rem;
    }

    .erp-row-hidden {
      display: none;
    }

    .erp-row-editable,
    .erp-row-selected {
      background-color: $washy-blue;
    }

    .erp-row-editable {
      &.mat-row {
        height: auto;
      }

      .mat-cell:not(.with-padding),
      .mat-cell:first-of-type:not(.with-padding),
      .mat-cell:last-of-type:not(.with-padding) {
        padding: 0;
      }

      .mat-form-field-wrapper {
        margin: 0;
        padding: 0;

        .mat-form-field-flex {
          margin-top: 0;

          .mat-form-field-outline-start,
          .mat-form-field-outline-end {
            border-radius: 0;
          }
        }

        .mat-form-field-subscript-wrapper {
          display: none;
        }
      }

      &.show-err .mat-form-field-subscript-wrapper {
        display: block;
        margin-top: 0;
        padding: 0 0.5rem;

        mat-error {
          font-size: 1.25rem;
        }

        mat-error:not(.tablet-err) {
          display: none;
        }
      }
    }

    .mat-table-sticky {
      position: sticky !important;
    }

    @at-root .erp-app {
      .erp-tablet-module {
        .mat-table {
          table-layout: auto;

          .mat-row {
            height: 3rem;
          }

          .mat-header-cell {
            line-height: 1.25rem;
            min-width: 0;
          }

          .mat-cell,
          .mat-cell:first-of-type,
          .mat-cell:last-of-type {
            font-size: 1.25rem;
          }

          .mat-footer-cell {
            font-size: 1.25rem;
          }

          .mat-sort-header-container {
            min-width: 0;
          }

          &.font-size-small {
            .mat-cell,
            .mat-cell:first-of-type,
            .mat-cell:last-of-type,
            .mat-button,
            .erp-link-button {
              font-size: 0.875rem;
            }
          }
        }
      }
    }
  }
}
/* stylelint-enable no-descending-specificity */
