.no-pointer-events {
  pointer-events: none;
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

.overflow-wrap-break-word {
  overflow-wrap: anywhere;
}

.white-space-normal {
  white-space: normal;
}

.white-space-no-wrap {
  white-space: nowrap;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.white-space-pre-line {
  white-space: pre-line;
}

.uppercase {
  text-transform: uppercase;
}

.line-height-normal {
  line-height: normal;
}

.text-bold {
  font-weight: bold;
}

.small-item {
  font-size: 1rem;
}

.x-scrollable {
  overflow-x: scroll;
}
