@import 'variables/color';

erp-app {
  .mat-stepper-horizontal {
    .mat-horizontal-content-container {
      padding: 1.5rem 0;
    }

    .mat-stepper-horizontal-line {
      border-color: $blue;
      border-width: 0.5rem;
      top: 0.5rem;
    }

    .mat-horizontal-stepper-header {
      padding: 0.5rem;

      &:hover,
      &:focus {
        background: none;
      }

      .mat-step-icon {
        display: none;
      }

      .mat-step-label {
        color: $light-graphite;
      }

      &[aria-labelledby='true'][aria-selected='false'] {
        &::before,
        &::after {
          border-color: $blue;
        }

        .mat-step-label {
          color: $blue;
        }
      }

      &[aria-labelledby='true'][aria-selected='true'] {
        &::before,
        &::after {
          border-color: $blue;
        }

        &::after {
          border-radius: 0.5rem;
          width: 0.5rem;
          right: calc(50% - 0.25rem);
        }

        .mat-step-label {
          color: $blue;
        }

        ~ .mat-stepper-horizontal-line {
          border: none;
        }

        ~ .mat-horizontal-stepper-header[aria-selected='false'] {
          &:last-child {
            &::before {
              border-radius: 0.5rem;
              width: 0.5rem;
              left: calc(50% - 0.25rem);
            }
          }

          &:not(:last-child) {
            &::before {
              border: none;
            }
          }

          &::after {
            border-radius: 0.5rem;
            width: 0.5rem;
            right: calc(50% - 0.25rem);
          }

          &[aria-labelledby='false'] {
            &:not(:last-child) {
              &::before {
                border: none;
              }
            }

            &:last-child {
              &::before {
                border-color: $light-graphite;
              }
            }
          }

          &[aria-labelledby='true'] {
            &:last-child {
              &::before {
                border-color: $blue;
              }
            }

            .mat-step-label {
              color: $blue;
            }
          }
        }
      }

      &::before,
      &::after {
        border-color: $light-graphite;
      }

      &:not(:last-child)::before,
      &:not(:last-child)::after,
      &:not(:first-child)::before,
      &:not(:first-child)::after {
        border-width: 0.5rem;
        top: 0.5rem;
        width: 50%;
      }

      &:last-child::before {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }

      &:first-child::after {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }
    }
  }
}
