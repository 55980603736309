.erp-app {
  .mat-select-panel {
    max-height: 15rem;
  }

  &-tablet {
    .mat-select-panel {
      max-height: 27rem;

      .hide-checkbox mat-pseudo-checkbox.mat-pseudo-checkbox {
        display: none;
      }

      .sticky {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 1;
        background: #fff;
      }
    }
  }
}
