@import 'variables/color';

@mixin erp-checkbox-color($theme) {
  $primary: map-get($theme, primary);

  .erp-app {
    .mat-checkbox {
      display: block;
    }

    .mat-checkbox-checked {
      .mat-checkbox-background {
        background: #d8ecf3;
        border-radius: 0;

        .mat-checkbox-checkmark-path {
          stroke: $shar-blue !important;
          stroke-width: 0.25rem;
        }
      }

      &.mat-checkbox-disabled {
        .mat-checkbox-background {
          background: $light-gray;

          .mat-checkbox-checkmark-path {
            stroke: $dark-gray !important;
          }
        }
      }
    }

    .mat-checkbox-layout {
      white-space: normal;
    }

    .mat-checkbox-ripple {
      display: none;
    }

    .erp-tablet-module {
      .mat-checkbox {
        .mat-checkbox-layout {
          font-weight: 400;
          font-size: 1.5rem;
          align-items: flex-start;

          .mat-checkbox-inner-container {
            height: 1.5rem;
            width: 1.5rem;
            margin: 0 1rem 0 0;
          }
        }

        span {
          line-height: 1.5rem;
        }
      }
    }
  }
}
