@use '@angular/material' as mat;
@use "sass:map";

@mixin erp-theme-reset($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $foreground: map.get($theme, foreground);

  a {
    color: mat.get-color-from-palette($primary, text);
    font-size: 0.875rem;
    text-decoration: none;
  }

  button,
  button[type='button'],
  button[type='submit'],
  [type='button'],
  [type='submit'] {
    appearance: none;
  }
}
