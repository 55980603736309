// To allow using group selectors with pseudoclasses and avoid copy-paste
/* stylelint-disable no-descending-specificity */
.erp-app {
  $sidenav-width: 20rem;
  $sidenav-collapsed-width: 2.5rem;

  mat-sidenav-container {
    display: block;
    min-height: inherit;
    width: 100%;

    aside {
      display: block;
      min-height: inherit;
      padding-bottom: 1.75rem;
    }

    .mat-sidenav {
      ::-webkit-scrollbar {
        width: 0.3rem;
      }

      ::-webkit-scrollbar-track {
        background-color: $transparent;
      }

      ::-webkit-scrollbar-thumb {
        background-color: $dark-gray;
        border-radius: 1rem;
      }

      .mat-drawer-inner-container {
        overflow-y: overlay;

        erp-sidenav-expansion-panel:last-child {
          .mat-accordion {
            .mat-expansion-panel {
              border-bottom: 0;
            }
          }
        }

        .nav-list-caption {
          font: 500 0.75rem;
          color: $gray-button;
          line-height: 2rem;
          padding-left: 2rem;
        }

        a.mat-list-item {
          padding-left: 2rem;
          color: $whitesmoke;
          font-size: 15px;
          height: 2.5rem;

          &.standalone {
            @extend .mat-list-item;

            border-bottom: $prussian-blue 1px solid;
            padding-left: 1rem;
            font-size: 1rem;
            height: 3.5rem;
          }

          &:hover:not(.standalone) {
            background-color: $prussian-blue;
          }
        }
      }
    }

    mat-sidenav,
    mat-sidenav-content {
      transition: all 0.1s linear;
    }

    mat-sidenav-content {
      width: inherit;
      display: block;
      min-height: inherit;
      margin-left: $sidenav-width;
      height: calc(100vh - 2rem);
    }

    mat-sidenav {
      width: $sidenav-width;
      padding: 4.5rem 0 4rem 0;
      left: 0;
    }

    mat-toolbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 4.5rem;
      padding: 0 1rem;
    }

    erp-sidenav-footer {
      height: 4rem;
    }

    &.sidenav-collapsed {
      aside {
        display: none;
      }

      mat-sidenav {
        left: $sidenav-collapsed-width - $sidenav-width;

        mat-toolbar {
          padding: 0 8px;
        }
      }

      mat-sidenav-content {
        margin-left: $sidenav-collapsed-width;
      }
    }
  }
}

.erp-tablet-module {
  $sidenav-width: 28rem;
  $sidenav-collapsed-width: 3.25rem;

  mat-sidenav-container {
    .mat-sidenav {
      .mat-drawer-inner-container {
        .nav-list-caption {
          font: 500 1rem;
          font-size: 1rem;
          line-height: 2.75rem;
        }

        a.mat-list-item {
          font-size: 1.25rem;
          height: 4rem;

          &.standalone {
            @extend .mat-list-item;

            font-size: 1.5rem;
            height: 5rem;
          }

          &:hover:not(.active) {
            background-color: inherit !important;
          }
        }
      }
    }

    mat-sidenav-content {
      margin-left: $sidenav-collapsed-width;
    }

    mat-toolbar {
      height: 8.5rem;
    }

    mat-sidenav {
      padding: 8rem 0 5.5rem 0;
      width: $sidenav-width;
    }

    erp-sidenav-footer {
      height: 5.5rem;
    }

    &.sidenav-collapsed {
      mat-sidenav {
        left: $sidenav-collapsed-width - $sidenav-width;

        mat-toolbar {
          padding: 0 6px;
        }
      }

      mat-sidenav-content {
        margin-left: $sidenav-collapsed-width;
      }
    }
  }
}
/* stylelint-enable no-descending-specificity */
