.erp-app {
  .mat-form-field {
    &:not(.textarea-wrapper) {
      .mat-form-field-flex {
        height: 2rem;
      }
    }

    &-appearance-outline {
      .mat-form-field-wrapper {
        margin-bottom: 0;

        .mat-form-field-flex {
          padding: 0 0.5rem;
        }
      }

      .mat-form-field-infix {
        padding: 0.48rem 0;
        border-top: none;
        width: auto;
      }

      .mat-form-field-outline {
        top: 0;

        &-start,
        &-end {
          border-width: 0.125rem;
        }
      }

      .mat-form-field-subscript-wrapper {
        margin-top: 0.25rem;
        padding-left: 0;
        padding-right: 0;
        overflow: visible;
        white-space: nowrap;
      }

      .mat-form-field-suffix {
        top: 0.3em;

        mat-icon {
          height: 1.375rem;
          width: 1.375rem;
        }
      }
    }

    .mat-select-arrow-wrapper {
      transform: translateY(0.125rem);

      &::after {
        background-position: center;
        background-repeat: no-repeat;
        background-size: 1.25rem;
        content: '';
        display: inline-block;
        height: 1.5rem;
        width: 1.5rem;
        mask: url(/assets/icons/action/chevron-down.svg);
        mask-size: contain;
        background-color: $charcoal;
        position: absolute;
        top: -0.3rem;
        right: -0.3rem;
      }

      .mat-select-arrow {
        border: none;
      }
    }

    &.mat-form-field-disabled {
      .mat-select-arrow-wrapper {
        &::after {
          background-color: $light-grey;
        }
      }

      .mat-form-field-outline {
        background-color: $whitesmoke;
      }
    }
  }

  .mat-hint,
  .mat-error {
    font-size: 0.75rem;
    line-height: normal;
  }

  .erp-app-tablet,
  .erp-tablet-module {
    .mat-form-field {
      margin-top: 0.75rem;
      font-size: 1.5rem;
      font-weight: 500;

      &:not(.textarea-wrapper) {
        .mat-form-field-flex {
          height: 3rem;
        }
      }

      textarea {
        font-size: 1.5rem;
        font-weight: 400;
      }

      &-appearance-outline {
        .mat-form-field-infix {
          padding: 0.6rem 0;
        }

        .mat-form-field-subscript-wrapper {
          margin-top: 0.5rem;
        }

        .mat-form-field-suffix {
          top: 0.4rem;

          mat-icon {
            height: 2rem;
            width: 2rem;
          }
        }
      }
      &.textarea-wrapper {
        font-size: 0.75rem;
      }

      .mat-select-arrow-wrapper {
        &::after {
          height: 2rem;
          width: 2rem;
        }
      }
    }

    .mat-table .mat-form-field {
      margin: 0;
      font-size: 1.25rem;

      .mat-form-field-infix {
        padding: 0.7rem 0;
      }
    }

    .medium {
      .mat-form-field {
        .mat-form-field-infix {
          padding-top: 1.2rem;
        }
        .mat-form-field-flex {
          height: 4rem;
        }
      }
      &.ng-invalid {
        .mat-form-field {
          .mat-form-field-infix {
            padding-top: 1.2rem;
          }
        }
      }
    }

    .label {
      font-size: 1.25rem;
    }

    mat-radio-button {
      margin-right: 3rem;
    }
  }
}
