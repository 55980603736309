$dark-teal: #00766c;
$teal: #26a69a;
$light-teal: #64d8cb;

$prussian-blue: #003959;
$navy-blue: #005680;
$blue: #0883b0;
$light-blue: #57b3e2;
$shar-blue: #00a7e1;
$light-shar-blue: #57c0e8;
$washy-blue: #e8f2f8;
$white-ice: #e6f4fb;
$botticelli: #d3dce7;

$charcoal: #242a33;
$dark-grey: #7b828c;
$grey: #9ca2ab;
$light-grey: #cdd3dd;
$dark-gray: $dark-grey;
$gray: $grey;
$light-gray: $light-grey;
$whitesmoke: #f5f7fa;
$gray-button: #a7adb9;
$dark-gray-button: #4c525c;
$light-grey-second: #f5f5f5;

$dark-mustard: #b28c00;
$mustard: #f6d55c;
$light-yellow: #ffffdb;

$dark-green: #005b13;
$lime: #7cb342;
$light-green: #ebf8ce;

$dark-graphite: #00111e;
$graphite: #253745;
$light-graphite: #4f6170;
$soft-shadow: #d8d8d8;

$dark-coral: #b51d11;
$coral: #ee553b;
$light-coral: #ffe9dd;

$transparent: #fff0;
