.erp-app {
  mat-expansion-panel {
    position: static;
    box-shadow: none!important;

    mat-expansion-panel-header {
      position: static;
      padding: 0;
      height: auto !important;

      .mat-content {
        overflow: visible;
      }

      .mat-expansion-indicator {
        position: absolute;
        right: 23px;
      }
    }

    .mat-expansion-panel-body {
      padding: 0;
    }
  }

  .erp-tablet-module {
    .mat-expansion-indicator {
      right: 27px;

      &::after {
        border-width: 0 3px 3px 0;
        padding: 4px;
        border-radius: 1px;
        color: #a7adb9;
      }
    }
  }
}
