@use '@angular/material' as mat;
@import '@angular/material/theming';
@import '~animate.css/animate.min.css';

@import 'variables';

@import 'mixins';
@import 'themes';
@import 'components';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies;
@include mat.legacy-core;

// Here we rewriting material fonts on our custom
@include mat.legacy-typography-hierarchy($erp-typography);

@import 'base'; /* stylelint-disable-line no-invalid-position-at-import-rule */

// Here we pass our theme to material and custom components
@include erp-theme-reset($erp-light-theme);
@include erp-component-theme($erp-light-theme);
@include erp-component-typography($erp-typography);
.erp-tablet-module,
.erp-app-tablet {
  @include mat.legacy-typography-hierarchy($erp-tablet-typography);
  @include erp-component-typography($erp-tablet-typography);
}

@include erp-component-custom-theme($erp-light-theme);

@import 'override'; /* stylelint-disable-line no-invalid-position-at-import-rule */
